import Vue from 'vue';
import axios from 'axios';
import { AccountingService } from '@/services/accountingService';
import { FileService } from '@/services/fileService';
import { VATService } from '@/services/vatService';
import { getAccountClass, getAccountGroup, formatAccountNumber, extendVAT } from '@/utils/accountingHelper';
import { PaymentService } from '@/services/paymentService';

const getDefaultState = () => ({
  accounts: {},
  VATList: {},
  VATListNow: [],
  balanceSheetList: [],
  trialBalanceList: [],
  manualTransactions: [],
  bulkActionFiles: [],
  currencies: [],
  paymentCountries: [],
  incomeStatement: null,
  paymentSources: {},
  accountsPromise: {},
});

const state = getDefaultState();

const getters = {
  vatDataByCode(state, getters) {
    return (vatCode) => {
      let res = {};
      if (vatCode) {
        res =
          getters.getVATList().find((vat) => {
            return '' + vat.code === '' + vatCode;
          }) || res;
      }
      return res;
    };
  },
  vatDataById: (_state, getters) => (vatId) => {
    return vatId ? getters.getVATList().find((vat) => vat.id === vatId) || {} : {};
  },
  accountDataByNumber(state) {
    return (accountNumber, companyId) => {
      if (accountNumber && state.accounts[companyId]) {
        const [acc, sub] = accountNumber.toString().split(':');

        return (
          state.accounts[companyId].find((account) => {
            return account.number.toString() === acc && (sub ? account.subNumber?.toString() === sub : true);
          }) || {}
        );
      }

      return {};
    };
  },
  companyAccounts(state, _getters, _rootState, rootGetters) {
    const companyId = rootGetters.selectedCompany?.id;

    if (!companyId) return [];

    const accounts = state.accounts[companyId] || [];

    return accounts.map((account) => {
      const searchboxTitle = `${account.number + (account.subNumber ? ':' + account.subNumber : '')} ${account.name}`;
      const listSearchTitle = `${account.number} ${account.name} ${account.vatCode}`.toLowerCase();
      const accountFullNumber = formatAccountNumber(account);

      return {
        ...account,
        searchboxTitle,
        listSearchTitle,
        accountFullNumber,
        originalName: account.originalName || account.name,
      };
    });
  },
  /**
   * Filter all parent accounts that have sub accounts
   */
  companyAccountsList(_state, getters) {
    return getters.companyAccounts.filter((account) => !(account.containsSubAccounts && !account.subNumber));
  },
  getPaymentSources(state) {
    return (companyId) => {
      const paymentSources = state.paymentSources[companyId] || [];
      return paymentSources.filter((s) => !s.hidden);
    };
  },
  getDefaultPaymentSource(state) {
    return (companyId, flags = []) => {
      const items = []
        .concat(state.paymentSources[companyId] || [])
        .filter((item) => !item.deleted && flags.every((flag) => item[flag]));

      if (items.length) {
        return items.sort((a, b) => (a.order ?? -1) < (b.order ?? -1))[0];
      }

      return {};
    };
  },
  getVATList(state) {
    return (date) => {
      if (state.VATList[date]) {
        return state.VATList[date].map(extendVAT);
      }
      // hack when there is no date and we want to use today => date will be undefined
      // eslint-disable-next-line dot-notation
      const result = state.VATList['undefined'];
      if (result && result.length) {
        return result.map(extendVAT);
      }
    };
  },
  getVATNowList(state) {
    return state.VATListNow.map(extendVAT);
  },
  getTrialBalanceList(state) {
    return state.trialBalanceList.map((item) => {
      return {
        group: getAccountGroup(item.account.number),
        class: getAccountClass(item.account.number),
        number: item.account.number,
        name: item.account.name,
        startBalance: item.startBalance,
        endBalance: item.endBalance,
        sum: item.sum,
      };
    });
  },
  getBalanceSheetList(state) {
    return state.balanceSheetList.map((item) => {
      return {
        group: getAccountGroup(item.account.number),
        class: getAccountClass(item.account.number),
        number: item.account.number,
        name: item.account.name,
        startBalance: item.startBalance,
        endBalance: item.endBalance,
        sum: item.sum,
      };
    });
  },
  getIncomeStatement(state) {
    return state.incomeStatement;
  },
  getCurrencies(state) {
    return state.currencies;
  },
  getPaymentCountries(state) {
    return state.paymentCountries;
  },
};

const actions = {
  async syncPaymentSources(
    { dispatch },
    { companyId, paymentSourcesToCreate, paymentSourcesToUpdate, paymentSourcesToDelete },
  ) {
    const result = [];
    paymentSourcesToCreate.forEach((paymentSource) => {
      result.push(dispatch('storePaymentSource', { companyId, paymentSource }));
    });
    paymentSourcesToDelete.forEach((paymentSourceId) => {
      result.push(dispatch('deletePaymentSource', { companyId, paymentSourceId }));
    });
    paymentSourcesToUpdate.forEach((paymentSource) => {
      result.push(dispatch('updatePaymentSource', { companyId, paymentSource }));
    });
    await Promise.all(result);
  },
  async deletePaymentSource({ commit }, { companyId, paymentSourceId }) {
    await AccountingService.deletePaymentSource(companyId, paymentSourceId);
    commit('deletePaymentSource', { companyId, paymentSourceId });
  },
  async storePaymentSource({ commit }, { companyId, paymentSource }) {
    const createdPaymentSource = await AccountingService.storePaymentSource(companyId, paymentSource);
    commit('storePaymentSource', { companyId, createdPaymentSource });
  },
  async updatePaymentSource({ commit }, { companyId, paymentSource }) {
    const updatedPaymentSource = await AccountingService.updatePaymentSource(companyId, paymentSource);
    commit('updatePaymentSource', { companyId, updatedPaymentSource });
  },
  async fetchPaymentSources({ commit }, { companyId }) {
    const paymentSources = await AccountingService.getPaymentSources(companyId);
    commit('setPaymentSources', { companyId, paymentSources });
  },
  async fetchAccounts({ commit, dispatch, state }, { companyId, ignoreCache }) {
    if (!state.accountsPromise[companyId]) {
      const accountsPromise = dispatch('actualAccountsFetch', { companyId });
      commit('setAccountsPromise', { accountsPromise, companyId });
    }
    await state.accountsPromise[companyId];
  },
  async actualAccountsFetch({ commit }, { companyId }) {
    const accounts = await AccountingService.getAccounts(companyId);
    commit('setAccounts', { accounts, companyId });
  },
  async fetchCurrencies({ commit }) {
    const currencies = await AccountingService.getCurrencies();
    commit('setCurrencies', { currencies });
  },
  async fetchPaymentCountries({ commit }) {
    const countries = await PaymentService.getCountries();
    commit('setPaymentCountries', { countries });
  },
  async storeCustomAccount({ commit }, { companyId, account }) {
    const createdAccount = await AccountingService.storeAccount(companyId, account);
    commit('storeCustomAccount', { createdAccount, companyId });
  },
  async updateAccount({ commit }, { companyId, account }) {
    const updatedAccount = await AccountingService.updateAccount(companyId, account);
    commit('updateAccount', { updatedAccount, companyId });
  },
  async fetchVATList(context, { companyId, date, fromCache }) {
    if (!fromCache || !context.state.VATList[date]) {
      const VATList = await VATService.getVatList(companyId, date);
      context.commit('setVATList', { VATList, date });
    }
  },
  async fetchVATListNow(context, { companyId, fromCache }) {
    if (!fromCache || !context.state.VATListNow.length) {
      // TODO also create a company bucket
      const VATList = await VATService.getVatList(companyId);
      context.commit('setVATListNow', VATList);
    }
  },
  uploadFile(_context, { companyId, file }) {
    return FileService.uploadFile(companyId, file);
  },
  async fetchTrialBalance(context, { companyId, fromCache, startDate, endDate }) {
    if (!fromCache || !context.getters.trialBalanceList.length) {
      try {
        const trialBalanceList = await AccountingService.getTrialBalanceList(companyId, startDate, endDate);
        context.commit('setTrialBalanceList', trialBalanceList);
      } catch (err) {
        if (axios.isCancel(err)) return;
        throw err;
      }
    }
  },
  async fetchBalanceSheet(context, { companyId, fromCache, filters }) {
    if (!fromCache || !context.getters.balanceSheetList.length) {
      try {
        const balanceSheetList = await AccountingService.getBalanceSheetList(companyId, filters);
        context.commit('setBalanceSheetList', balanceSheetList);
      } catch (err) {
        if (axios.isCancel(err)) return;
        throw err;
      }
    }
  },
  async fetchIncomeStatement(context, { companyId, fromCache, filters }) {
    if (!fromCache || !context.getters.balanceSheetList.length) {
      try {
        const incomeStatement = await AccountingService.getIncomeStatement(companyId, filters);
        context.commit('setIncomeStatement', incomeStatement);
      } catch (err) {
        if (axios.isCancel(err)) return;
        throw err;
      }
    }
  },
  resetState({ commit }) {
    commit('RESET_STATE');
  },
};

const mutations = {
  setPaymentSources(state, { companyId, paymentSources }) {
    Vue.set(state.paymentSources, companyId, paymentSources);
  },
  setCurrencies(state, { currencies }) {
    state.currencies = currencies;
  },
  setPaymentCountries(state, { countries }) {
    state.paymentCountries = countries;
  },
  deletePaymentSource(state, { companyId, paymentSourceId }) {
    const paymentSource = state.paymentSources[companyId];
    if (paymentSource) {
      const paymentSourceIdx = paymentSource.findIndex((source) => source.id === paymentSourceId);
      paymentSource.splice(paymentSourceIdx, 1);
    }
  },
  updatePaymentSource(state, { companyId, paymentSource }) {
    const statePaymentSource = state.paymentSources[companyId];
    if (statePaymentSource && paymentSource) {
      const paymentSourceIdx = statePaymentSource.findIndex((source) => {
        return source.id === paymentSource.id;
      });
      Vue.set(statePaymentSource, paymentSourceIdx, paymentSource);
    }
  },
  setAccounts(state, { accounts, companyId }) {
    Vue.set(state.accounts, companyId, accounts);
  },
  setAccountsPromise(state, { accountsPromise, companyId }) {
    state.accountsPromise[companyId] = accountsPromise;
  },
  storePaymentSource(state, { companyId, createdPaymentSource }) {
    const paymentSource = state.paymentSources[companyId];
    if (!paymentSource) {
      state.paymentSources[companyId] = [createdPaymentSource];
    } else {
      paymentSource.push(createdPaymentSource);
    }
  },
  storeCustomAccount(state, { createdAccount, companyId }) {
    state.accounts[companyId].push(createdAccount);
  },
  updateAccount(state, { updatedAccount, companyId }) {
    const accountIdx = state.accounts[companyId].findIndex((acc) => acc.number === updatedAccount.number);
    Vue.set(state.accounts[companyId], accountIdx, updatedAccount);
  },
  setVATList(state, { VATList, date }) {
    if (!state.VATList[date]) {
      Vue.set(state.VATList, date, VATList);
    }
  },
  setTrialBalanceList(state, trialBalanceList) {
    state.trialBalanceList = trialBalanceList;
  },
  setBalanceSheetList(state, balanceSheetList) {
    state.balanceSheetList = balanceSheetList;
  },
  setVATListNow(state, VATList) {
    state.VATListNow = VATList;
  },
  setIncomeStatement(state, incomeStatement) {
    state.incomeStatement = incomeStatement;
  },
  setBulkActionFiles(state, files) {
    state.bulkActionFiles = files;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
